<template>
    <v-container
        class="px-0">
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <ASuccessWithoutReload :api="this.api" @close="fetch()"/>
        <ANotAllowed
            :notAllowed="isNotAllowed" @close="closeNotAllowed"></ANotAllowed>
        <v-skeleton-loader v-if="api.isLoading && isFirstLoading" type="table" ref="skeleton">
        </v-skeleton-loader>
        <v-data-table
            v-else
            :items="data.data"
            :lazy="true"
            :loading="api.isLoading"
            show-expand
            :options.sync="options"
            :headers="dataHeader"
            :items-per-page.sync="options.itemsPerPage"
            :server-items-length="data.total"
            :search="search"
            item-key="user_id"
            :expanded.sync="expanded"
            :sort-desc:sync="options.sortDesc"
            :sort-by.sync="options.sortBy"
            :footer-props="{
                itemsPerPageOptions:[20,40,100],
                showCurrentPage:true,
            }"
            @update:options="onPaginationUpdate"
            class="elevation-1 custom-table">
            <template
                v-slot:item.index="{item}">
                {{ ((options.page-1) * options.itemsPerPage) + (data.data.indexOf(item)+1) }}
            </template>
            <template   
                v-slot:item.user_status="{item}">
                <div
                    v-if="!$permission.allowedAdminOperationRole($store.getters.getRole)">
                    <span
                        v-if="item.user_status==1">
                        Active
                    </span>
                    <span
                        v-else>
                        Inactive
                    </span>
                </div>
                <div
                    v-else>
                    <v-btn
                        v-if="item.user_status==1"
                        @click="activateAccountStatus(item.user_id,0)"
                        color="green white--text"
                        width="90"
                        small>
                        Active
                    </v-btn>
                    <v-btn
                        v-if="item.user_status==0"
                        @click="activateAccountStatus(item.user_id,1)"
                        small
                        width="90"
                        color="red white--text">
                        Deactive
                    </v-btn>

                </div>


                <!-- <v-chip
                    outlined
                    small
                    text
                    elevation="0"
                    color="green white--text"
                    v-if="item.user_status==1">
                    Active
                </v-chip>
                <v-chip
                    outlined
                    small
                    text 
                    elevation="0"
                    color="red white--text"
                    v-else>
                    Deactive
                </v-chip>
              -->
            </template>
            <template
                v-slot:item.email="{item}">
                <span
                    style="font-size:14px;overflow-wrap: break-word">
                    {{ item.email }}
                </span>
            </template>
            <template   
                v-slot:item.email_verified_at="{item}">
                <v-btn
                    @click="activateEmailVerification(item.user_id,0)"
                    width="100"
                    small
                    elevation="0"
                    color="green white--text"
                    v-if="item.email_verified_at!=null">
                    <span>
                        Active
                    </span>
                </v-btn>
                <v-btn
                    @click="activateEmailVerification(item.user_id,1)"
                    width="100"
                    small 
                    elevation="0"
                    color="red white--text"
                    v-else>
                    <span>
                        Deactive
                    </span>
                </v-btn>
            </template>
            <template
                v-slot:expanded-item="{headers,item}">
                <td
                    :colspan="headers.length">
                    <v-row
                        class="mx-2 my-2 px-2 ">
                        <v-col
                            cols="12" sm="3">
                            <!-- <div
                                class="d-flex justify-start pb-0 mb-0">
                                <span
                                    class="">
                                    Roles
                                </span>
                            </div>
                            <v-col
                                v-if="item.role!=null"
                                class="px-0 py-0">
                                <span>
                                    {{ "1 ) "+item.role.charAt(0).toUpperCase()+item.role.slice(1)+" ( Masterclass )"  }}
                                </span>
                            </v-col>
                            <div   
                            v-if="item.roles!=null">
                                <v-col
                                    v-if="Object.entries(item.roles).length!=0">
                                    <v-row
                                        v-for="(item,index) in item.roles"
                                        :key="index">
                                        <div
                                            class="d-flex justify-start py-0 my-0">
                                            <span>
                                                {{ 1+index+1+" ) "+item.role_name.charAt(0).toUpperCase()+item.role_name.slice(1).replaceAll("_"," ") }} {{ AAERole.includes(item.role_name)?" ( Ask An Expert ) ":"" }} {{ VisionRole.includes(item.role_name)?" ( Vision )":"" }}
                                            </span>

                                        </div>
                                    </v-row>
                                </v-col>

                            </div> -->
                            <div
                            class="d-flex justify-start mt-2">
                            <span>
                                    Subscription type :
                                    <b>
                                        {{ item.subcription_type }}
                                    </b>
                                </span>
                            </div>
                            <div
                            class="d-flex justify-start mt-2">
                            <span>
                                Branch : 
                                <b>
                                    {{ item.branch }}
                                </b>
                            </span>
                        </div>
                        <div
                            class="d-flex justify-start mt-2">
                            <span>
                                Email verification status:
                                <b>
                                    {{ item.email_verified_at!=null?"Verified":"Unverified" }}
                                </b>
                            </span>
                        </div>
                        </v-col>
                    </v-row>
                </td>   
            </template>
            <template
                v-slot:item.fname="{item}">
                <router-link
                    style="font-size:14px"
                    class="px-0"
                    :to="{name:'PageStaffDetail',params:{id:item.user_id}}">
                        {{ item.fname }} 
                </router-link>
            </template>
            <template
                v-slot:item.mobile="{item}">
                <span
                    style="font-size:14px">
                    {{ item.mobile }}
                </span>
            </template>
            <template
                v-slot:top>
                <v-toolbar
                    flat 
                    class="text-h4 pt-4"
                    height="auto">
                        <v-row no-gutters>
                            <v-col class="mt-3 mx-3" >
                                <v-row class="mb-3">
                                    <v-toolbar-title class="font-weight-bold text-h4 my-3">
                                        Staff
                                    </v-toolbar-title>
                                    <v-spacer>
                                    </v-spacer>
                                    <v-btn color="primary"
                                    v-if="$permission.allowedAdminOperationRole($store.getters.getRole)"
                                     @click="checkAllowedRoles('create')"   class="mx-3 mt-1">
                                        New
                                    </v-btn> 
                                </v-row>
                                <v-row>
                                        <v-text-field
                                        v-model="search"
                                        dense
                                        label="Search"
                                        outlined
                                        @input="handleSearchInput"
                                        clearable>
                                    </v-text-field>
                                </v-row>    
                            </v-col>
                        </v-row>
                    </v-toolbar>
                
            </template>
            <template
                v-slot:item.isMasterClass="{item}">
                <!-- <v-icon
                    v-if="item.role!=null"
                    color="green">
                    mdi-check    
                </v-icon> -->
                <div
                    class="d-flex justify-center"
                    v-if="item.role!=null">
                    <span>
                        {{ item.role.toLowerCase() }}
                    </span>
                </div>
                <v-icon
                    small
                    v-else>
                    fa-ban
                </v-icon>
            </template>
            <template
                v-slot:item.isAAE="{item}">
                <div
                    v-if="item.roles!=null && item.roles.some(role=>AAERole.includes(role.role_name))">
                    <span
                        color="green">
                        {{ item.roles.filter(role=>AAERole.includes(role.role_name))[0].role_name.replaceAll('_',' ') }}   
                    </span>
                </div>
                <v-icon
                    small
                    v-else>
                    fa-ban
                </v-icon>
            </template>
            <template
                v-slot:item.isVision="{item}">
                <div
                    v-if="item.roles!=null && item.roles.some(role=>VisionRole.includes(role.role_name))">
                    <span>
                        {{ item.roles.filter(role=>VisionRole.includes(role.role_name))[0].role_name.replaceAll('_',' ') }}  
                    </span>
                </div>
                <v-icon
                    small
                    v-else>
                    fa-ban
                </v-icon>
            </template>
            <template
                v-slot:item.action="{item}">
                <div
                v-if="allowedRoles.some(role=>$store.getters.getRole.includes(role))"
                    class="d-flex justify-start">
                    <v-menu
                        offset-y>
                        <template
                            v-slot:activator="{ on,attrs}">
                            <v-btn
                                small
                                v-bind="attrs"
                                v-on="on"
                                color="primary">
                                Actions
                                <v-icon>
                                    mdi-chevron-up
                                </v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item
                                style="font-size:14px"
                                v-for="(data,index) in actions"
                                :key="index"
                                @click="executeActions(data,item)">
                                <!-- <div
                                    v-if="data.action==='DeactivateAccount'">
                                    <span
                                        v-if="item.user_status==1">
                                        Deactivate account
                                    </span>
                                    <span
                                        v-else>
                                        Activate account
                                    </span>
                                </div> -->
                                <v-menu
                                    v-if="data.action==='PageUserSendEmail'"  
                                    offset-y>
                                    <template
                                        v-slot:activator="{on,attrs}">
                                        <span
                                        v-bind="attrs"
                                        v-on="on">
                                            <!-- {{ data.name}} -->
                                            Send email
                                            <v-icon>
                                                mdi-chevron-up
                                            </v-icon>
                                        </span>
                                    </template>
                                    <v-list>
                                        <v-list-item
                                            style="font-size:14px"
                                            v-for="(data,index) in emailActions"
                                            :key="index"
                                            @click="sendEmail(data,item)">
                                                {{ data.name }}
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                                {{ data.name }}
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
                <div
                    v-else>
                    <v-icon
                        small>
                        fa-ban
                    </v-icon>
                </div>
            </template>
            <!-- <template
                v-slot:item.subcription_type="{item}">
                <span
                    v-if="item.subcription_type=='undefined' || item.subcription_type ==null">
                    -
                </span>
                <span
                    v-else>
                    {{ item.subcription_type }}
                </span>

            </template> -->
            <!-- <template
                v-slot:item.branch="{item}">
                <span
                    v-if="item.branch=='undefined' || item.branch == null || item.branch=='null'">
                    -
                </span>
                <span
                    v-else>
                    {{ item.branch }}
                </span>

            </template> -->
            <template
                v-slot:item.created_at="{item}">
                <span
                    style="font-size:14px">
                    {{ convertTimeZone(item.created_at) }}
                </span>
            </template>
        </v-data-table>
        
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import ANotAllowed from '../common/ANotAllowed.vue';
import ASuccessWithoutReload from '../common/ASuccessWithoutReload.vue';
export default {
    components:{
        ANotAllowed,
        ASuccessWithoutReload,
    },
    computed: mapState({
    //
}),
    props:[
    //
    ],
    data: () => ({
        expanded:[],
        actions:[
            {
                name:'View detail',
                action:'PageStaffDetail',
                redirected:true,
            },
            {
                name:'Edit detail',
                action:'PageStaffUpdate',
                redirected:true,
            },
            {
                name:'',
                action:'PageUserSendEmail',
                redirected:true,
            },
            // {
            //     name:'',
            //     action:'DeactivateAccount',
            //     redirected:false,
            // },
        ],
        emailActions : [
            {
                name:'Reset password',
                action:'resetPassword',
                value:1,
            },
            {
                name:'Verify email',
                action:'verifyEmail',
                value:3,
            },

        ],
        search:"",
        data:null,
        dataHeader:[
            {
                text:' ',
                value:'data-table-expand'
            },
            {
                text:'#',
                value:'index',
                width:'2px',
            },
            // {
            //     text:'user id',
            //     value:'user_id'
            // },
            {
                text:'First name',
                value:'fname'
            },
            {
                text:'Last name',
                value:'lname'
            },
            // {
            //     text:'User',
            //     value:'user_name',
            //     width:'2px',
            // },
            {
                text:'Email',
                value:'email',
                width:'2px'
            },
            {
                text:'Mobile',
                value:'mobile',
                width:'2px',

            },
            {
                text:'Masterclass',
                value:'isMasterClass',
                width:'2px'

            },
            {
                text:'AAE',
                value:'isAAE',
                width:'2px'

            },
            {
                text:'Vision',
                value:'isVision',
                width:'2px'

            },
            // {
            //     text:'Enrolled date',
            //     value:'created_at'
            // },
            {
                text:'Account status',
                value:'user_status',
            },
            {
                text:'Action',
                value:'action'
            },
            // {
            //     text:'Email verified status',
            //     value:'email_verified_at'
            // },
        ],
        AAERole:[
        'client',
        'consultant',
        'tax_expert',
        'admin',
        'manager',
        ],
        VisionRole:[
        'salesperson',
        'customer_service',
        'sales_team_lead',
        'vision_admin',
        'operation',
        'sales_manager',
        'developer',
        'marketing',
        ],
        allowedRoles:[
            'vision_admin',
            'operation',
            'developer'
        ],
        isNotAllowed:false,
        options:{
            itemsPerPage:20,
            page:1,
            sortBy:[],
            search:'',
            statusSearch:'',
            emailSearch:'',
            accessSearch:'',
        },
        optionsStatus:
        [
            {
                text:'Active',
                value:1,
            },
            {
                text:'Inactive',
                value:0,
            }
        ],
        optionsEmailStatus:[
            {
                text:'Verified',
                value:1,
            },
            {
                text:'Unverified',
                value:0,
            }
        ],
        optionsAccessStatus:[
            {
                text:'Masterclass',
                value:'masterclass',
            },
            {
                text:'Ask An Expert',
                value:'aae',
            },
            {
                text:'Vision',
                value:'vision'
            }
        ],
        isFirstLoading:true,
        isPending:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class==="getListOfStaff") {
                    this.data = resp.data;
                    this.isFirstLoading = false;
            }
            if(resp.class==="updateUserStatus" ||resp.class==="updateUserEmailVerification"){
                this.api.isSuccesful = true;
                this.api.success = "Succesfully Update Client Detail"
            }
            if(resp.class==='sendEmail') {
                this.api.isSuccesful = true;
                this.api.success = "Succesfully send the email";
            }
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            this.api.isSuccesful = false;
            let fetchStaffListApi = this.fetchStaffList();
            this.$api.fetch(fetchStaffListApi);
        },
        fetchStaffList() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+'/internal/user?page='+this.options.page+"&sortBy="+this.options.sortBy+"&sortDesc="+this.options.sortDesc+"&search="+this.options.search+"&rowsPerPage="+this.options.itemsPerPage+"&statusSearch="+this.options.statusSearch+"&emailSearch="+this.options.emailSearch+"&accessSearch="+this.options.accessSearch;
            return tempApi;
        },
        onPaginationUpdate(pagination){

        if(this.isFirstLoading){
            this.isFirstLoading = false;
        }
        else{
            this.options = this.$_.clone(pagination);
            this.fetch();
        }
        },
       
        validateInput(){
            this.isPending=true;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        handleSearchInput() {
            setTimeout(()=>{
                this.options.search = this.search;
            },1000);
        },
        submit(){
            this.isPending = false;
            let reApplyCouponApi = this.reApplyCoupon();
            this.$api.fetch(reApplyCouponApi);
        },
        convertTimeZone(time){
            return this.$moment(time).format('LLL');
        },
        checkAllowedRoles(type) {
            if(this.allowedRoles.some(role=>this.$store.getters.getRole.includes(role))) {
                if(type==='create') {
                    this.$router.push({name:'PageStaffCreate',params:{id:33}});
                }
                // if(type==='onboarding') {
                //     this.$router.push({name:'PageStaffRegister'});
                // }
                return true;
            }
            this.isNotAllowed = true;
            return false;

        },
        closeNotAllowed() {
            this.isNotAllowed = false;
        },
        activateEmailVerification(userId,status) {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "PUT";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/users/email/id/"+userId;
            tempApi.params = {
                'status':status,
            };
            this.$api.fetch(tempApi);
        },
        activateAccountStatus(userId,status) {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "PUT";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/users/status/id/"+userId;
            tempApi.params = {
                'status':status,
            };
            this.$api.fetch(tempApi);
        },
        executeActions(action,item) {

            if(action.action=='PageUserSendEmail') {
                return 1;
            }
            if(action.redirected) {
                this.$router.push({name:action.action,params:{'id':item.user_id}});
            }
            if(!action.redirected) {
                if(action.action==='DeactivateAccount') {
                    this.activateAccountStatus(item.user_id,item.user_status==1?0:1);
                }
            }
        },
        sendEmail(action,user) {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/email/send";
            let formData = new FormData;
            formData.append('id',user.user_id);
            formData.append('template',action.value);
            tempApi.params = formData;
            this.$api.fetch(tempApi);
        }
    }
}
</script>
<style scoped>
span {
    font-size:14px;
}
.v-data-table {
  table {
    tr {
      th, td {
        padding: 0 8px !important;
      }
    }
  }
}

</style>