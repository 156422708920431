var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-0"},[_c('AConfirmation',{attrs:{"isShow":_vm.isPending},on:{"cancel":_vm.cancelSubmit,"confirm":_vm.submit}}),_c('ASuccessWithoutReload',{attrs:{"api":this.api},on:{"close":function($event){return _vm.fetch()}}}),_c('ANotAllowed',{attrs:{"notAllowed":_vm.isNotAllowed},on:{"close":_vm.closeNotAllowed}}),(_vm.api.isLoading && _vm.isFirstLoading)?_c('v-skeleton-loader',{ref:"skeleton",attrs:{"type":"table"}}):_c('v-data-table',{staticClass:"elevation-1 custom-table",attrs:{"items":_vm.data.data,"lazy":true,"loading":_vm.api.isLoading,"show-expand":"","options":_vm.options,"headers":_vm.dataHeader,"items-per-page":_vm.options.itemsPerPage,"server-items-length":_vm.data.total,"search":_vm.search,"item-key":"user_id","expanded":_vm.expanded,"sort-desc:sync":_vm.options.sortDesc,"sort-by":_vm.options.sortBy,"footer-props":{
            itemsPerPageOptions:[20,40,100],
            showCurrentPage:true,
        }},on:{"update:options":[function($event){_vm.options=$event},_vm.onPaginationUpdate],"update:itemsPerPage":function($event){return _vm.$set(_vm.options, "itemsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.options, "itemsPerPage", $event)},"update:expanded":function($event){_vm.expanded=$event},"update:sortBy":function($event){return _vm.$set(_vm.options, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.options, "sortBy", $event)}},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(((_vm.options.page-1) * _vm.options.itemsPerPage) + (_vm.data.data.indexOf(item)+1))+" ")]}},{key:"item.user_status",fn:function(ref){
        var item = ref.item;
return [(!_vm.$permission.allowedAdminOperationRole(_vm.$store.getters.getRole))?_c('div',[(item.user_status==1)?_c('span',[_vm._v(" Active ")]):_c('span',[_vm._v(" Inactive ")])]):_c('div',[(item.user_status==1)?_c('v-btn',{attrs:{"color":"green white--text","width":"90","small":""},on:{"click":function($event){return _vm.activateAccountStatus(item.user_id,0)}}},[_vm._v(" Active ")]):_vm._e(),(item.user_status==0)?_c('v-btn',{attrs:{"small":"","width":"90","color":"red white--text"},on:{"click":function($event){return _vm.activateAccountStatus(item.user_id,1)}}},[_vm._v(" Deactive ")]):_vm._e()],1)]}},{key:"item.email",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"14px","overflow-wrap":"break-word"}},[_vm._v(" "+_vm._s(item.email)+" ")])]}},{key:"item.email_verified_at",fn:function(ref){
        var item = ref.item;
return [(item.email_verified_at!=null)?_c('v-btn',{attrs:{"width":"100","small":"","elevation":"0","color":"green white--text"},on:{"click":function($event){return _vm.activateEmailVerification(item.user_id,0)}}},[_c('span',[_vm._v(" Active ")])]):_c('v-btn',{attrs:{"width":"100","small":"","elevation":"0","color":"red white--text"},on:{"click":function($event){return _vm.activateEmailVerification(item.user_id,1)}}},[_c('span',[_vm._v(" Deactive ")])])]}},{key:"expanded-item",fn:function(ref){
        var headers = ref.headers;
        var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',{staticClass:"mx-2 my-2 px-2 "},[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('div',{staticClass:"d-flex justify-start mt-2"},[_c('span',[_vm._v(" Subscription type : "),_c('b',[_vm._v(" "+_vm._s(item.subcription_type)+" ")])])]),_c('div',{staticClass:"d-flex justify-start mt-2"},[_c('span',[_vm._v(" Branch : "),_c('b',[_vm._v(" "+_vm._s(item.branch)+" ")])])]),_c('div',{staticClass:"d-flex justify-start mt-2"},[_c('span',[_vm._v(" Email verification status: "),_c('b',[_vm._v(" "+_vm._s(item.email_verified_at!=null?"Verified":"Unverified")+" ")])])])])],1)],1)]}},{key:"item.fname",fn:function(ref){
        var item = ref.item;
return [_c('router-link',{staticClass:"px-0",staticStyle:{"font-size":"14px"},attrs:{"to":{name:'PageStaffDetail',params:{id:item.user_id}}}},[_vm._v(" "+_vm._s(item.fname)+" ")])]}},{key:"item.mobile",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(item.mobile)+" ")])]}},{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"text-h4 pt-4",attrs:{"flat":"","height":"auto"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mt-3 mx-3"},[_c('v-row',{staticClass:"mb-3"},[_c('v-toolbar-title',{staticClass:"font-weight-bold text-h4 my-3"},[_vm._v(" Staff ")]),_c('v-spacer'),(_vm.$permission.allowedAdminOperationRole(_vm.$store.getters.getRole))?_c('v-btn',{staticClass:"mx-3 mt-1",attrs:{"color":"primary"},on:{"click":function($event){return _vm.checkAllowedRoles('create')}}},[_vm._v(" New ")]):_vm._e()],1),_c('v-row',[_c('v-text-field',{attrs:{"dense":"","label":"Search","outlined":"","clearable":""},on:{"input":_vm.handleSearchInput},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)],1)]},proxy:true},{key:"item.isMasterClass",fn:function(ref){
        var item = ref.item;
return [(item.role!=null)?_c('div',{staticClass:"d-flex justify-center"},[_c('span',[_vm._v(" "+_vm._s(item.role.toLowerCase())+" ")])]):_c('v-icon',{attrs:{"small":""}},[_vm._v(" fa-ban ")])]}},{key:"item.isAAE",fn:function(ref){
        var item = ref.item;
return [(item.roles!=null && item.roles.some(function (role){ return _vm.AAERole.includes(role.role_name); }))?_c('div',[_c('span',{attrs:{"color":"green"}},[_vm._v(" "+_vm._s(item.roles.filter(function (role){ return _vm.AAERole.includes(role.role_name); })[0].role_name.replaceAll('_',' '))+" ")])]):_c('v-icon',{attrs:{"small":""}},[_vm._v(" fa-ban ")])]}},{key:"item.isVision",fn:function(ref){
        var item = ref.item;
return [(item.roles!=null && item.roles.some(function (role){ return _vm.VisionRole.includes(role.role_name); }))?_c('div',[_c('span',[_vm._v(" "+_vm._s(item.roles.filter(function (role){ return _vm.VisionRole.includes(role.role_name); })[0].role_name.replaceAll('_',' '))+" ")])]):_c('v-icon',{attrs:{"small":""}},[_vm._v(" fa-ban ")])]}},{key:"item.action",fn:function(ref){
        var item = ref.item;
return [(_vm.allowedRoles.some(function (role){ return _vm.$store.getters.getRole.includes(role); }))?_c('div',{staticClass:"d-flex justify-start"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" Actions "),_c('v-icon',[_vm._v(" mdi-chevron-up ")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.actions),function(data,index){return _c('v-list-item',{key:index,staticStyle:{"font-size":"14px"},on:{"click":function($event){return _vm.executeActions(data,item)}}},[(data.action==='PageUserSendEmail')?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" Send email "),_c('v-icon',[_vm._v(" mdi-chevron-up ")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.emailActions),function(data,index){return _c('v-list-item',{key:index,staticStyle:{"font-size":"14px"},on:{"click":function($event){return _vm.sendEmail(data,item)}}},[_vm._v(" "+_vm._s(data.name)+" ")])}),1)],1):_vm._e(),_vm._v(" "+_vm._s(data.name)+" ")],1)}),1)],1)],1):_c('div',[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fa-ban ")])],1)]}},{key:"item.created_at",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.convertTimeZone(item.created_at))+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }